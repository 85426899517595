L.Layer.include({
  options: {
    groupName: false
  }
});

L.Map.include({
  getLatLngsLayers: function(layer = this) {
    let latlngs = [];
    layer.eachLayer(l => {
      if (typeof l.getLatLng === "function") {
        latlngs.push(l.getLatLng());
      } else if (typeof l.getLatLngs === "function") {
        latlngs.push(l.getLatLngs());
      } else if (typeof l.getLatLngsLayers === "function") {
        latlngs = latlngs.concat(l.getLatLngsLayers());
      }
    });
    return latlngs;
  },

  fitLayer: function(layer, options = {}) {
    if (this.hasLayer(layer)) {
      let latlngs = this.getLatLngsLayers(layer);
      if (latlngs.length) {
        return this.fitBounds(latlngs, options);
      }
    }

    return this.fitWorld(options);
  },
  fitLayers: function(options = {}) {
    let latlngs = this.getLatLngsLayers();
    return (latlngs.length) ? this.fitBounds(latlngs, options) : this.fitWorld(options);
  }
});
