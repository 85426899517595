L.LayerGroup.include({
  getLatLngsLayers: function() {
    let latlngs = [];
    this.eachLayer(layer => {
      if (typeof layer.getLatLng === "function") {
        latlngs.push(layer.getLatLng());
      } else if (typeof layer.getLatLngs === "function") {
        latlngs.push(layer.getLatLngs());
      } else if (typeof layer.getLatLngsLayers === "function") {
        latlngs = latlngs.concat(layer.getLatLngsLayers());
      }
    });
    return latlngs;
  },
});
