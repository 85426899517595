"use strict";

L.PopupAnimate = L.Popup.extend({
  _sourceOptions: {},
  options: {
    animate: {
      center: true,
      hideOthers: false,
      options: {}
    }
  },
  onAdd: function(map) {
    //map.freeze(true);

    if (this._source) {
      this._sourceOptions = Object.assign({}, this._source.options);
    }

    // Marker
    if (typeof this.options.animate.options != "undefined" && (this._source)) {
      if (this._source instanceof L.Path) {
        this._source.setStyle(this.options.animate.options);
      }
      else if (this._source instanceof L.Marker && typeof this.options.animate.options.icon != "undefined") {
        let icon = Object.assign({}, this._sourceOptions.icon.options, this.options.animate.options.icon);
        if (typeof icon.iconUrl !== "undefined" && icon.iconUrl) {
          icon = L.icon(icon);
        }
        this._source.setIcon(icon);
      }
    }

    // Center
    if (this.options.animate.center !== false) {
      let pt = map.project(this.getLatLng());
      map.panTo(map.unproject(pt), { animate: true });
    }

    L.Popup.prototype.onAdd.call(this, map);

    // Redraw
    if ((this._source instanceof L.Path)) {
      this._source.redraw();
    }
  },
  onRemove: function(map) {
    //map.freeze(false);

    // Reset
    if (typeof this.options.animate.options != "undefined" && (this._source)) {
      if (this._source instanceof L.Path) {
        this._source.setStyle(this._sourceOptions);
      }
      else if (this._source instanceof L.Marker && typeof this.options.animate.options.icon != "undefined") {
        this._source.setIcon(this._sourceOptions.icon);
      }
    }

    L.Popup.prototype.onRemove.call(this, map);

    // Redraw
    if ((this._source instanceof L.Path)) {
      this._source.redraw();
    }
  }
});

L.popupAnimate = function (options, source) {
  return new L.PopupAnimate(options, source);
};
