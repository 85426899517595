L.IdentifiedGroup = L.LayerGroup.extend({
  _initIdentifiedGroup: function() {
    this.identifier = this._leaflet_id;
  }
});

L.identifiedGroup = function(layers, options) {
  return new L.IdentifiedGroup(layers, options);
};

L.IdentifiedGroup.addInitHook('_initIdentifiedGroup');

L.Map.include({
  _identifiedGroups: [],

  // Interact with layers which have an "identifier"
  getLayersIdentifiers: function() {
    let identifiers = [];
    this.eachLayer(layer => {
      if (typeof layer.identifier !== "undefined") {
        identifiers.push(layer.identifier);
      }
    }, this);
    return identifiers;
  },
  getLayerIdentifier: function(layer) {
    return (typeof layer.identifier !== "undefined") ? layer.identifier : this.getLayerId(layer);
  },
  getIdentifiedLayers: function() {
    let layers = [];
    this.eachLayer(layer => {
      if (layer instanceof L.IdentifiedGroup) {
        this.push(layer);
      }
    }, layers);
    return layers;
  },

  // Interact with instance
  addIdentifiedGroup: function(identifier) {
    if (!this.hasIdentifiedGroup(identifier)) {
      group = new L.identifiedGroup();
      group.identifier = identifier;
      this._identifiedGroups.push(group);
      this.addLayer(group);
    }

    return this;
  },
  getIdentifiedGroups: function() {
    return this._identifiedGroups;
  },
  hasIdentifiedGroup: function(layer) {
    return !!layer && (this.getIdentifiedGroup(layer));
  },
  getIdentifiedGroup: function(layer) {
    let identifier = this.getIdentifiedGroupIdentifier(layer) || layer;
    return this.getIdentifiedGroups().find(group => group.identifier === identifier);
  },
  getIdentifiedGroupIdentifier: function(layer) {
    return (layer instanceof L.IdentifiedGroup) ? layer.identifier : false;
  },

  // Actions
  showAllIdentifiedGroups: function() {
    return this.getIdentifiedGroups().forEach(this.addLayer.bind(this));
  },
  showIdentifiedGroup: function(layer) {
    let group = this.getIdentifiedGroup(layer);
    if (group) {
      this.addLayer(group);
    }
    return this;
  },
  hideIdentifiedGroup: function(layer) {
    let group = this.getIdentifiedGroup(layer);
    if (group) {
      this.removeLayer(group);
    }
    return this;
  },
  toggleIdentifiedGroup: function(layer) {
    let group = this.getIdentifiedGroup(layer);
    if (group) {
      if (this.hasLayer(group)) {
        this.removeLayer(group);
      } else {
        this.addLayer(group);
      }
    }
    return this;
  },
  showIdentifiedGroupOnly: function(layer) {
    let group = this.getIdentifiedGroup(layer);
    if (group) {
      this.getIdentifiedGroups().forEach(this.removeLayer.bind(this));
      this.addLayer(group);
    }
    return this;
  },
  hideIdentifiedGroupOnly: function(layer) {
    let group = this.getIdentifiedGroup(layer);
    if (group) {
      this.getIdentifiedGroups().forEach(this.addLayer.bind(this));
      this.removeLayer(group);
    }
    return this;
  }
});
