"use strict";

L.Map.include({
  isFrozen: function() {
    return this._frozen;
  },
  freeze: function(value) {
    this._frozen = Boolean(value);
  }
});
