"use strict";

import {calculatePosition} from '../geometry/ImageUtil';

L.Popup.include({
  _getAnchor: function () {
    let anchor = [0, 0];
    if (this._source && this._source._getPopupAnchor) {
      anchor = this._source._getPopupAnchor();
      if (typeof anchor === "string" && this._source.getElement) {
        let img = this._source.getElement();
        let size = [this._source.getElement().width, this._source.getElement().height];
        let iconAnchor = this._source.options.icon.options.iconAnchor || [0, 0];
        anchor = calculatePosition(anchor, img, size, iconAnchor);
      }
    }
    return L.point(anchor);
  }
});
