import * as DomUtil from "leaflet/src/dom/DomUtil";
import PolylineUtil from 'polyline-encoded';
import uuidv4 from "uuid/v4";

Drupal.acreat = Drupal.acreat || {};
Drupal.acreat.leaflet = {

  // Default map options from https://leafletjs.com/reference-1.3.2.html#map
  defaults: {
    fitLayers: true,
    fitLayersOptions: {
      padding: [20, 20]
    }
  },

  // {L.Map[]} created.
  maps: [],

  /**
   * Set default Leaflet Map options.
   *
   * @param {Object} [defaults]
   *   An object literal containing map options.
   * @param {Object} [defaults.map]
   *   An object literal containing only default Map options as described in
   *   Leaflet documentation.
   * @param {Object} [defaults.icon]
   *   An object literal containing only default Icon options as described in
   *   Leaflet documentation.
   * @param {Object} [defaults.marker]
   *   An object literal containing only default Marker options as described
   *   in Leaflet documentation.
   * @param {Object} [defaults.tilelayer]
   *   An object literal containing only default TileLayer options as
   *   described in Leaflet documentation.
   *
   * @return {Object}
   *   Return Drupal.acreat.leaflet.
   */
  setDefaults: function (defaults) {
    jQuery.extend(true, this.defaults, defaults || {});
    return this;
  },

  /**
   * Create a Leaflet Map.
   *
   * @param {(string|DOMElement)} instance
   *   A <div> DOM ID string (without the #) or a <div> DOMElement instance.
   * @param {Object} [options]
   *   An object literal containing all options.
   * @param {Object} options.map
   *   An object literal containing only Map options as described in Leaflet
   *   documentation.
   * @param {Object} [options.icon]
   *   An object literal containing only Icon options as described in Leaflet
   *   documentation.
   * @param {Object} [options.marker]
   *   An object literal containing only Marker options as described in
   *   Leaflet documentation.
   * @param {Object} [options.markers]
   *   An array of Leaflet LatLng object or similar to add on the map as
   *   markers.
   * @param {Object} [options.tilelayer]
   *   An object literal containing only TileLayer options as described in
   *   Leaflet documentation.
   *
   * @return {L.Map}
   *   An instance of Leaflet Map.
   */
  createMap: function (instance, options) {
    if (!instance) {
      throw new Exception("Acreat Leaflet init(): instance parameter.");
    }

    // Options.
    options = jQuery.extend(true, {}, this.defaults.map, options || {});

    // Override options with data-* attributes.
    let params = this.collectSelectorParams(instance);
    options.markers = jQuery.merge(options.markers || [], params.markers || []);
    options.center = jQuery.extend(true, {}, options.center, params.location || {});

    // Map.
    let map = L.mapMarks(instance, options);
    map.zoomControl.setPosition("bottomleft");

    // TileLayer.
    L.tileLayer(this.defaults.tilelayer.url, this.defaults.tilelayer.options).addTo(map);

    // Markers.
    if (options.markers && Array.isArray(options.markers)) {
      const markersLayoutOptions = {
        fitLayers: options.fitLayers,
        fitLayersOptions: options.fitLayersOptions,
      }
      this.addLayers(map, options.markers, markersLayoutOptions);
    }

    // Zoom.
    if (typeof options.zoom != "undefined") {
      map.setZoom(options.zoom);
    }

    // Add map in internal var.
    this.maps.push(map);

    // Bind load.
    map.on("load", event => {
      if (options.fitLayers) {
        let fitLayersOptions = options.fitLayersOptions || this.defaults.fitLayersOptions;
        map.fitLayers(fitLayersOptions);
      }
    });

    // Bind unload.
    map.on("unload", event => {
      let _this = Drupal.acreat.leaflet;
      let index = _this.maps.findIndex( map => {
        return map.getContainer().id == event.target.getContainer().id;
      });
      if (~index) {
        _this.maps.splice(index, 1);
      }
    });

    // Hide markers if needed.
    map.fire("zoomend");

    return map;
  },

  /**
   * Create a Leaflet Map.
   *
   * @param {string} selector
   *   A CSS selector string.
   * @param {Object} [options]
   *   See createMap options parameter documentation.
   *
   * @return {L.Map[]}
   *   An array of Leaflet Map's instance.
   */
  createMapFromSelector: function (selector, options) {
    let maps = [];

    document.querySelectorAll(selector).forEach(element => {
      element.id = element.id || uuidv4();
      maps.push(this.createMap(element.id, options));
    });

    return maps;
  },

  /**
   * Return the Leaflet Map.
   *
   * @param {(string|DOMElement|null)} instance
   *   A <div> DOM ID string (without the #) or a <div> DOMElement instance.
   *
   * @return {L.Map}
   *   An instance of Leaflet Map.
   */
  getMap: function(instance) {
    instance = (typeof instance === "string") ? DomUtil.get(instance) : instance;
    return (instance instanceof HTMLElement) ? this.maps.find( map => {
      return map.getContainer().id == instance.id;
    }) : this.maps[0];
  },

  _bindZoom: function(map, marker, minZoom, maxZoom) {
    map.on("zoomend", (event => {
      if (!map.isFrozen()) {
        if (map.getZoom() >= minZoom && (typeof maxZoom == "undefined" || map.getZoom() <= maxZoom)) {
          map.addMark(marker);
        }
        else {
          map.removeMark(marker);
        }
      }
    }).bind(this));
  },

  /**
   * Build a {L.Popup} and bind it to marker.
   *
   * @param {(string|DOMElement|Object)} instance
   *   A <div> DOM ID string (without the #) or a <div> DOMElement instance.
   *   Even an Object compatible with {L.Popup} should work.
   * @param {L.marker} marker instance
   *   The marker use to bind the popup.
   *
   * @return {L.Popup}
   *   An instance of Leaflet Popup if created.
   */
  _bindPopup: function(popup, marker) {
    popup = this.normalizePopup(popup);
    if (typeof marker === "object" && typeof marker.bindPopup === "function") {
      marker.bindPopup(popup);
    }

    return popup;
  },

  /**
   * Build a {L.Popup} and bind it to marker.
   *
   * @param {(string|DOMElement|Object)} instance
   *   A <div> DOM ID string (without the #) or a <div> DOMElement instance.
   *   Even an Object compatible with {L.Popup} should work.
   * @param {L.marker} marker instance
   *   The marker use to bind the popup.
   * @param {L.mapMarks} map instance
   *   The map use to get all others marker.
   */
  _bindPopupAnimation: function(popup, marker, map) {
    popup = this.normalizePopup(popup);

    let markers;

    // OnOpen.
    marker.on("popupopen", (event => {
      map.freeze(true);
      if (popup.options.animate.hideOthers) {
        markers = map.getGroupMarks().getLayers();
        map.getGroupMarks().eachLayer((layer) => {
          if (layer._leaflet_id !== marker._leaflet_id) {
            map.removeMark(layer);
          }
        });
      }
    }).bind(this));

    // OnClose.
    marker.on("popupclose", (event => {
      map.freeze(false);
      if (popup.options.animate.hideOthers) {
        this.addLayers(map, markers, { fitLayers: false });

        map.fire("zoomend");
      }
    }).bind(this));
  },

  /**
   * Add one or more markers to a map.
   *
   * @param {L.Map} map
   *   An instance of Leaflet Map.
   * @param {L.LatLng} latlng
   *   A Leaflet LatLng object or any other compatible representation.
   * @param {Object} [options]
   *   (Optional) A literal object describing the marker options.
   *   It can contain an L.icon describing the icon options.
   *
   * @return {L.Marker}
   *   Return a L.Marker object or undefined.
   */
  addMarker: function (map, latlng, options) {
    latlng = this.normalizeLatLng(latlng);
    options = jQuery.extend(true, { minZoom: 0, maxZoom: undefined, groupName: "marks" }, this.defaults.marker, options || {});

    let marker = undefined;
    let icon = jQuery.extend(true, { type: "icon" }, this.defaults.icon, options.icon || {});

    if (latlng instanceof L.LatLng) {

      if (icon.type == "icon" && typeof icon.iconUrl !== "undefined" && icon.iconUrl) {
        options.icon = L.icon(icon);
      } else if (icon.type == 'divIcon') {
        options.icon = L.divIcon(icon);
      }

      marker = L.marker(latlng, options);

      // Add marker to map if it can.
      if ((map.getZoom() >= options.minZoom) && (typeof options.maxZoom == "undefined" || map.getZoom() <= options.maxZoom)) {
        map.addLayer(marker);
      }

      // Add marker's content
      if (icon.type == 'divIcon' && typeof icon.content !== "undefined") {
        map.on('zoomend moveend', this._divIconRefresh.bind(null, marker, icon));
        map.getGroupMarks().on('spiderfied', this._divIconRefresh.bind(null, marker, icon));
      }

      // Refit.
      options = jQuery.extend(true, { fitLayers: true, fitLayersOptions: { padding: [20, 20] } }, options || {});
      if (options.fitLayers) {
        map.fitLayers(options.fitLayersOptions);
      }

      // Zoom options.
      if (typeof options.maxZoom != "undefined" || options.minZoom != 0) {
        this._bindZoom(map, marker, options.minZoom, options.maxZoom);
      }

      // Popup options.
      if (typeof options.popup != "undefined") {
        let popup = this.normalizePopup(options.popup);

        this._bindPopup(popup, marker);
        this._bindPopupAnimation(popup, marker, map);
      }
    }

    return marker;
  },

  /**
   * Refresh divIcon marker contents with the icon content.
   *
   * @param {L.Marker} marker
   *   An instance of Leaflet Marker.
   * @param {Object} icon
   *   A litaral object describing the icon options with a content property.
   */
  _divIconRefresh: function (marker, icon) {
    if (marker.getElement() && icon.content && typeof icon.content === 'string') {
      marker.getElement().innerHTML = icon.content;
    }
  },

  /**
   * Add one or more markers to a map.
   *
   * @param {L.Map} map
   *   An instance of Leaflet Map.
   * @param {L.LatLng} latlng
   *   A Leaflet LatLng object or any other compatible representation.
   * @param {Object} [options]
   *   (Optional) A literal object describing the marker options.
   *
   * @return {L.Circle}
   *   Return a L.Circle object or undefined.
   */
  addCircle: function (map, latlng, options) {
    latlng = this.normalizeLatLng(latlng);
    options = jQuery.extend(true, { color: "blue", fillColor: "#30f", fillOpacity: 0.5, radius: 2500, minZoom: 0, maxZoom: undefined, groupName: "marks" }, this.defaults.circle, options);

    let marker = undefined;

    if (latlng instanceof L.LatLng) {

      marker = L.circle(latlng, options);

      // Add marker to map if it can.
      if ((map.getZoom() >= options.minZoom) && (typeof options.maxZoom == "undefined" || map.getZoom() <= options.maxZoom)) {
        map.addLayer(marker);
      }

      // Refit.
      options = jQuery.extend(true, { fitLayers: true, fitLayersOptions: { padding: [20, 20] } }, options || {});
      if (options.fitLayers) {
        map.fitLayers(options.fitLayersOptions);
      }

      // Zoom options.
      if (typeof options.maxZoom != "undefined" || options.minZoom != 0) {
        this._bindZoom(map, marker, options.minZoom, options.maxZoom);
      }

      // Popup options.
      if (typeof options.popup != "undefined") {
        let popup = this.normalizePopup(options.popup);

        this._bindPopup(popup, marker);
        this._bindPopupAnimation(popup, marker, map);
      }
    }

    return marker;
  },

  /**
   * Add one or more polygons to a map.
   *
   * @param {L.Map} map
   *   An instance of Leaflet Map.
   * @param {L.LatLng} latlng
   *   A Leaflet LatLng object or any other compatible representation.
   * @param {Object} [options]
   *   (Optional) A literal object describing the marker options.
   *
   * @return {L.Polygon}
   *   Return a L.Circle object or undefined.
   */
  addPolygon: function (map, latlngs, options) {
    options = jQuery.extend(true, { minZoom: 0, maxZoom: undefined, groupName: "marks" }, this.defaults.polygon, options);

    let polygon = undefined;

    if (Array.isArray(latlngs)) {
      polygon = L.polygon(latlngs, options);

      // Add polygon to map if it can.
      if ((map.getZoom() >= options.minZoom) && (typeof options.maxZoom == "undefined" || map.getZoom() <= options.maxZoom)) {
        map.addLayer(polygon);
      }

      // Refit.
      options = jQuery.extend(true, { fitLayers: true, fitLayersOptions: { padding: [20, 20] } }, options || {});
      if (options.fitLayers) {
        map.fitLayers(options.fitLayersOptions);
      }

      // Zoom options.
      if (typeof options.maxZoom != "undefined" || options.minZoom != 0) {
        this._bindZoom(map, polygon, options.minZoom, options.maxZoom);
      }

      // Popup options.
      if (typeof options.popup != "undefined") {
        let popup = this.normalizePopup(options.popup);

        this._bindPopup(popup, polygon);
        this._bindPopupAnimation(popup, polygon, map);
      }
    }

    return polygon;
  },

  /**
   * Add one or more markers to a map.
   *
   * @param {L.Map} map
   *   An instance of Leaflet Map.
   * @param {L.LatLng} latlng
   *   A Leaflet LatLng object or any other compatible representation.
   * @param {string} [type]
   *   (Optional) A literal type describing the layer.
   * @param {Object} [options]
   *   (Optional) A literal object describing the marker options.
   *
   * @return {L.Layer}
   *   Return a L.Layer object or undefined.
   */
  addLayer: function(map, latlng, type, options) {
    let layer;
    type = type || "marker";

    switch (type) {
      case "circle":
        layer = this.addCircle(map, latlng, options);
        break;

      case "polygon":
        layer = this.addPolygon(map, latlng, options);
        break;

      case "marker":
      default:
        layer = this.addMarker(map, latlng, options);
    }

    return layer;
  },

  /**
   * Add multiple layers.
   *
   * @param {L.Map} map
   *   An instance of Leaflet Map.
   * @param {L.Layer[]} layers
   *   An array of L.Layer objects or any other compatible representation.
   * @param {Object} [options]
   *   (Optional) A literal object describing options.
   *
   * @return {L.Marker[]|L.Circle[]}
   *   Return an array with all markers created.
   */
  addLayers: function (map, layers, options) {
    let markers = [];
    options = jQuery.extend(true, { fitLayers: true, fitLayersOptions: { padding: [20, 20] } }, options || {});

    if (!Array.isArray(layers)) {
      layers = [layers];
    }

    layers.forEach(layer => {
      if(layer instanceof L.Marker || layer instanceof L.Circle) {
        map.addLayer(layer);
        markers.push(layer);
      }
      else {
        if (typeof layer == "object") {
          layer.options = jQuery.extend(true, {}, options, layer.options || {});
          layer.options.fitLayers = false;

          // Set icon.
          if (typeof layer.icon !== "undefined") {
            layer.options.icon = layer.icon;
            delete layer.icon;
          }
        }

        if (typeof layer == "object" && layer.type === "polygon") {
          if (typeof layer.latlngs === "string") {
            layer.latlngs = PolylineUtil.decode(layer.latlngs);
          }
          this.addLayer(map, layer.latlngs, layer.type, layer.options);
          markers.push(layer);
        }
        else if (typeof layer == "object" && typeof layer.type !== "undefined") {
          this.addLayer(map, this.normalizeLatLng(layer), layer.type, layer.options);
          markers.push(layer);
        }
        else if (this.isLatLngCompatible(layer)) {
          this.addMarker(map, this.normalizeLatLng(layer), layer.options || {});
          markers.push(layer);
        }
      }
    });

    if (markers.length && options.fitLayers) {
      map.fitLayers(options.fitLayersOptions);
    }

    return markers;
  },

  /**
   * Add multiple layers.
   *
   * @param {L.Map} map
   *   An instance of Leaflet Map.
   * @param {L.LatLng[]} latlngs
   *   An array of L.LatLng objects or any other compatible representation.
   * @param {Object} [options]
   *   (Optional) A literal object describing the marker options.
   *   It can contain an L.icon describing the icon options.
   *
   * @return {L.Layers[]}
   *   Return an array with all markers created.
   */
  _addLayers: function (type, map, latlngs, options) {
    if (!Array.isArray(latlngs)) {
      latlngs = [latlngs];
    }

    options = jQuery.extend(true, { fitLayers: true, fitLayersOptions: { padding: [20, 20] } }, options || {});
    let markers = [];
    let fitLayers = options.fitLayers;

    for (let i = latlngs.length - 1; i >= 0; i--) {
      let latlng = this.normalizeLatLng(latlngs[i]);

      if (latlng instanceof L.LatLng) {
        options = options || {};
        options.fitLayers = false;

        let m = this.addLayer(map, latlng, type, options);

        if (typeof m !== "undefined" && m !== null) {
          markers.push(m);
        }
      }
    }

    if (markers.length && fitLayers) {
      map.fitLayers(options.fitLayersOptions);
    }

    return markers;
  },

  /**
   * Add multiple markers.
   *
   * @param {L.Map} map
   *   An instance of Leaflet Map.
   * @param {L.LatLng[]} latlngs
   *   An array of L.LatLng objects or any other compatible representation.
   * @param {Object} [options]
   *   (Optional) A literal object describing the marker options.
   *   It can contain an L.icon describing the icon options.
   *
   * @return {L.Marker[]}
   *   Return an array with all markers created.
   */
  addMarkers: function (map, latlngs, options) {
    return this._addLayers("marker", map, latlngs, options);
  },

  /**
   * Add multiple circle.
   *
   * @param {L.Map} map
   *   An instance of Leaflet Map.
   * @param {L.LatLng[]} latlngs
   *   An array of L.LatLng objects or any other compatible representation.
   * @param {Object} [options]
   *   (Optional) A literal object describing the circle options.
   *
   * @return {L.Marker[]}
   *   Return an array with all markers created.
   */
  addCircles: function (map, latlngs, options) {
    return this._addLayers("circle", map, latlngs, options);
  },

  /**
   * Add multiple polygons.
   *
   * @param {L.Map} map
   *   An instance of Leaflet Map.
   * @param {L.LatLng[]} latlngs
   *   An array of L.LatLng objects or any other compatible representation.
   * @param {Object} [options]
   *   (Optional) A literal object describing the circle options.
   *
   * @return {L.Marker[]}
   *   Return an array with all markers created.
   */
  addPolygons: function (map, latlngs, options) {
    return this._addLayers("polygon", map, latlngs, options);
  },

  /**
   * Add layers from CSS selector.
   *
   * @param {L.Map} map
   *   An instance of Leaflet Map.
   * @param {(string|string[])} selector
   *   A CSS selector string leading to a DOMElement that has a data-location
   *   attribute. The value of this attribute can be a JSON object like
   *   { "lat": 0.0, "lng": 0.0 } or an array like [0.0, 0.0].
   * @param {Object} [options]
   *   (Optional) A literal object describing the marker options.
   *   It can contain an L.icon describing the icon options.
   *
   * @return {L.Layers[]}
   *   Return an array with all markers created.
   */
  _addLayersFromSelector: function (type, map, selector, options) {
    if (Array.isArray(selector)) {
      selector = selector.join(", ");
    }

    if (typeof selector !== "string") {
      selector = "";
    }

    let markers = [];
    document.querySelectorAll(selector).forEach((element, index) => {
      let params = this.collectSelectorParams(element);
      let latlng = this.normalizeLatLng(params.location);

      if (latlng instanceof L.LatLng) {
        options = jQuery.extend(true, { fitLayers: true, fitLayersOptions: { padding: [20, 20] } }, options, params.options || {});

        // Set icon.
        if (typeof params.icon !== "undefined") {
          options.icon = params.icon;
        }

        markers.push(this.addLayer(map, latlng, type, options));
      }
    });

    return markers;
  },

  /**
   * Add markers from CSS selector.
   *
   * @param {L.Map} map
   *   An instance of Leaflet Map.
   * @param {(string|string[])} selector
   *   A CSS selector string leading to a DOMElement that has a data-location
   *   attribute. The value of this attribute can be a JSON object like
   *   { "lat": 0.0, "lng": 0.0 } or an array like [0.0, 0.0].
   * @param {Object} [options]
   *   (Optional) A literal object describing the marker options.
   *   It can contain an L.icon describing the icon options.
   *
   * @return {L.Marker[]}
   *   Return an array with all markers created.
   */
  addMarkersFromSelector: function (map, selector, options) {
    return this._addLayersFromSelector("marker", map, selector, options);
  },

  /**
   * Add circles from CSS selector.
   *
   * @param {L.Map} map
   *   An instance of Leaflet Map.
   * @param {(string|string[])} selector
   *   A CSS selector string leading to a DOMElement that has a data-location
   *   attribute. The value of this attribute can be a JSON object like
   *   { "lat": 0.0, "lng": 0.0 } or an array like [0.0, 0.0].
   * @param {Object} [options]
   *   (Optional) A literal object describing the marker options.
   *   It can contain an L.icon describing the icon options.
   *
   * @return {L.Marker[]}
   *   Return an array with all markers created.
   */
  addCirclesFromSelector: function (map, selector, options) {
    return this._addLayersFromSelector("circle", map, selector, options);
  },

  /**
   * Add circles from CSS selector.
   *
   * @param {L.Map} map
   *   An instance of Leaflet Map.
   * @param {(string|string[])} selector
   *   A CSS selector string leading to a DOMElement that has a data-location
   *   attribute. The value of this attribute can be a JSON object like
   *   { "lat": 0.0, "lng": 0.0 } or an array like [0.0, 0.0].
   * @param {Object} [options]
   *   (Optional) A literal object describing the marker options.
   *   It can contain an L.icon describing the icon options.
   *
   * @return {L.Marker[]}
   *   Return an array with all markers created.
   */
  addPolygonsFromSelector: function (map, selector, options) {
    return this._addLayersFromSelector("polygon", map, selector, options);
  },

  /**
   * Collect data parameters based on a given selector.
   *
   * @param {(string|DOMElement)} instance
   *   A <div> DOM ID string (without the #) or a <div> DOMElement instance.
   * @param {String} key
   *   (Optional) key of the element to get.
   *
   * @return {*}
   *   Return a jQuery array-like object composed from data-* attribute
   *   values found with the instance.
   */
  collectSelectorParams: function(instance, key) {
    let params = {};

    instance = (typeof instance === "string") ? DomUtil.get(instance) : instance;
    let data = jQuery(instance).data();

    for (let property in data) {
      switch (property) {
        case "latitude":
        case "lat":
          params.location = params.location || {"lat": undefined, "lng": undefined};
          params.location.lat = data[property];
          break;

        case "longitude":
        case "lon":
        case "lng":
          params.location = params.location || {"lat": undefined, "lng": undefined};
          params.location.lng = data[property];
          break;

        case "location":
          let location = data[property];
          if (typeof location === "object" && location.lat && location.lng) {
            params.location = location;
          }
          else if (typeof location === "string") {
            params.location = JSON.parse(location);

            if (Array.isArray(params.location) && this.isLatLngCompatible(params.location)) {
              params.location = [params.location];
            }
          }
          break;

        case "options":
          params.options = jQuery.extend(true, params.options, data[property] || {});
          break;

        case "markers":
          params.markers = [];
          let markers = (typeof data[property] === "string") ? [data[property]] : data[property];
          markers.forEach(marker => {
            params.markers.push((typeof marker === "string") ? this.collectSelectorParams(marker) : marker);
          });
          break;

        case "zoom":
          params[property] = data[property];
          break;

        case "icon":
        default:
          params.options = params.options || {[property]: undefined};
          params.options[property] = data[property];
      }
    }

    return (typeof key !== "undefined") ? (typeof params[key]) ? params[key] : undefined : params;
  },

  /**
   * Determine if a variable is a compatible Leaflet LatLng object.
   *
   * @param {*} something
   *   An object.
   *
   * @return {boolean}
   *   Return true if the given oject is compatible or false if not.
   */
  isLatLngCompatible: function (something) {
    let isCompatible = false;

    if (something instanceof L.LatLng) {
      isCompatible = true;
    }

    else if (Array.isArray(something) && typeof something[0] !== "object" && something.length >= 2 && something.length <=3) {
      isCompatible = true;
    }

    else if (something && typeof something === "object" &&
      (something.hasOwnProperty("lat") || something.hasOwnProperty("latitude")) &&
      (something.hasOwnProperty("lng") || something.hasOwnProperty("lon") || something.hasOwnProperty("longitude"))
    ){
      isCompatible = true;
    }

    return isCompatible;
  },

  /**
   * Convert a variable compatible with Leaflet LatLng object.
   *
   * @param {*} something
   *   An object.
   *
   * @return {LatLng}
   *   Return LatLng if the given oject is compatible or null if not.
   */
  normalizeLatLng: function(something) {
    if (something instanceof L.LatLng) {
      return something;
    }

    if (this.isLatLngCompatible(something)) {
      if (Array.isArray(something)) {
        something = L.latLng(something);
      }
      else if (typeof something === "object") {
        let latlng = {};

        // Latitude.
        if (something.hasOwnProperty("lat")) {
          latlng.lat = something.lat;
        }
        else if(something.hasOwnProperty("latitude")) {
          latlng.lat = something.latitude;
        }

        // Longitude
        if (something.hasOwnProperty("lng")) {
          latlng.lng = something.lng;
        }
        else if(something.hasOwnProperty("longitude")) {
          latlng.lng = something.longitude;
        }
        else if(something.hasOwnProperty("lon")) {
          latlng.lng = something.lon;
        }

        // alt.
        if (something.hasOwnProperty("alt")) {
          latlng.alt = something.alt;
        }
        else if(something.hasOwnProperty("altitude")) {
          latlng.alt = something.alt;
        }

        something = L.latLng(latlng);
      }
    }

    if (something instanceof L.LatLng) {
      return something;
    }
    else {
      return null;
    }
  },

  normalizePopup: function(something) {
    if (something instanceof L.Popup) {
      return something;
    }

    if (typeof something === "string") {
      something = {
        content: something,
        options: {}
      };
    }

    let content;
    if (typeof something === "object" && typeof something.content !== "undefined") {
      // Normalize options.
      something.options = something.options || Object.assign({}, something.options, something);
      delete something.options.content;
      delete something.options.options;

      // Build content.
      something.content = DomUtil.get(something.content) || something.content;
      if (typeof something.content === "object") {
        content = something.content.cloneNode(true);
      }
      else {
        content = document.createElement("div");
        content.innerHTML = something.content;
      }
      content.id = uuidv4();

      // Build popup.
      something = L.popupAnimate(something.options);
      something.setContent(content);
    }

    return something;
  }
};
