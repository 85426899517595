"use strict";

import {Point, toPoint} from 'leaflet/src/geometry/Point';

export function calculatePosition(position, img, size, anchor) {
  if (img) {
    if (!(size instanceof Point)) {
      size = toPoint(size) || toPoint(img.naturalWidth, img.naturalHeight);
    }

    switch (position) {
      case "top":
      case "left":
      case "topleft":
      case "lefttop":
        position = [0, 0];
        break;

      case "right":
      case "topright":
        position = [size.x, 0];
        break;

      case "center":
      case "topcenter":
      case "centertop":
        position = [size.x/2, 0];
        break;

      case "bottom":
      case "bottomleft":
      case "leftbottom":
        position = [0, size.y];
        break;

      case "bottomright":
      case "rightbottom":
        position = [size.x, size.y];
        break;

      case "bottomcenter":
      case "centerbottom":
        position = [size.x/2, size.y];
        break;

      case "leftcenter":
      case "centerleft":
        position = [0, size.y/2];
        break;

      case "centercenter":
        position = [size.x/2, size.y/2];
        break;

      case "rightcenter":
      case "centerright":
        position = [size.x, size.y/2];
        break;

      default:
        // Nothing to do.

    }

    if (!(anchor instanceof Point)) {
      anchor = toPoint(anchor) || toPoint(0, 0);
    }

    if (Array.isArray(position) && position.length === 2) {
      position[0] -= anchor.x;
      position[1] -= anchor.y;
    }
  }

  return position;
}
