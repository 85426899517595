import "leaflet.markercluster";
import * as Util from "leaflet/src/core/Util";

L.MapMarks = L.Map.extend({
  options: {
    limit: 0,
  },

  _initWithGoogleGrid: function(options = {}) {
    this._googleGrid = L.gridLayer.googleMutant({
      type: options.type || "roadmap",
      styles: options.styles || [],
    });
    this._googleGrid.addTo(this);
  },

  _initGroupMarks: function() {
    if (!!this.options.google) {
      this._initWithGoogleGrid(this.options.google);
    }

    // Fix "Uncaught Map has no maxZoom specified" error.
    // Source: https://github.com/Leaflet/Leaflet.markercluster/issues/611#issuecomment-277670244
    this._layersMaxZoom = 19;

    if (this.options.cluster) {
      this._groupMarks = L.markerClusterGroup(this.options.cluster);
    } else {
      this._groupMarks = L.identifiedGroup();
    }

    // Fix : Correctly add identifier
    // even if it is in a cluter mode.
    this._groupMarks.identifier = "marks";

    this.addLayer(this._groupMarks);
  },

  getGroupMarks: function() {
    return this._groupMarks;
  },

  // Interact with group "marks"
  addMark: function(layer) {
    layer.options.groupName = "marks";
    return this.addLayer(layer);
  },
  removeMark: function(layer) {
    layer.options.groupName = "marks";
    return this.removeLayer(layer);
  },
  getMarks: function() {
    return this._groupMarks.getLayers();
  },
  // fitLayer|fitLayers|getLatLngsLayers defined in Layer.js
  fitMarks: function() {
    return this.fitBounds(this.getLatLngsLayers(this._groupMarks));
  },

  // Override Layer functions.
  addLayer: function(layer) {
    if (!layer._layerAdd) {
      throw new Error('The provided object is not a Layer.');
    }

    // Already added.
    if (this.hasLayer(layer)) {
      return this;
    }

    // On this function because layer is not added yet.
    if (layer.identifier === this._groupMarks.identifier || layer instanceof L.IdentifiedGroup) {
      this._identifiedGroups.push(layer);
    }

    if (layer.options.groupName && !layer._groupToAdd) {
      if (!this.hasIdentifiedGroup(layer.options.groupName)) {
        this.addIdentifiedGroup(layer.options.groupName);
      }
      let group = this.getIdentifiedGroup(layer.options.groupName);
      if (!group.hasLayer(layer)) {
        layer._groupToAdd = group;
        group.addLayer(layer);
        return this;
      }
    }

    L.Map.prototype.addLayer.call(this, layer);

    return this;
  },
  removeLayer: function(layer) {
    // Already removed.
    if (!this.hasLayer(layer)) {
      return this;
    }

    L.Map.prototype.removeLayer.call(this, layer);

    layer._groupToAdd = null;

    return this;
  },
  hasLayer: function(layer) {
    return (!!layer && (
      layer in this._layers ||
      this.getLayerId(layer) in this._layers ||
      this.getLayersIdentifiers().includes(layer)
    ));
  },
  getLayer: function(layer) {
    if (!!layer) {
      if (layer in this._layers) {
        return this._layers[layer];
      }
      else if (this.getLayerId(layer) in this._layers) {
        return this._layers[this.getLayerId(layer)];
      }
      else if (this.getLayersIdentifiers().includes(layer)) {
        this.eachLayer(l => {
          if (layer === l.identifier) {
            layer = l;
          }
        });
        return layer;
      }
    }

    return false;
  },
  getLayerId: function(layer) {
    return (typeof layer === "object") ? Util.stamp(layer) : false;
  }
});

L.mapMarks = function(id, options) {
  return new L.MapMarks(id, options);
}

L.MapMarks.addInitHook("_initGroupMarks");
