"use strict";

import {calculatePosition} from '../../geometry/ImageUtil';

L.Icon.include({
  _setIconStyles: function (img, name) {
    let options = this.options;
    let sizeOption = options[name + 'Size'];

    if (typeof sizeOption === 'number') {
      sizeOption = [sizeOption, sizeOption];
    }
    let size = L.point(sizeOption);

    if (typeof options.iconAnchor === "string") {
      options.iconAnchor = calculatePosition(options.iconAnchor, img, size);
    }
    let anchor = L.point(name === 'shadow' && options.shadowAnchor || options.iconAnchor || size && size.divideBy(2, true));

    img.className = 'leaflet-marker-' + name + ' ' + (options.className || '');

    if (anchor) {
      img.style.marginLeft = (-anchor.x) + 'px';
      img.style.marginTop  = (-anchor.y) + 'px';
    }

    if (size) {
      img.style.width  = size.x + 'px';
      img.style.height = size.y + 'px';
    }
  },
});
